<style lang="css">
    @font-face {
        font-family: "Segoe UI";
        src: url(//semalt.com/css/fonts/SegoeUI.ttf);
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: "Segoe UI";
        src: url(//semalt.com/css/fonts/SegoeUI-Light.ttf);
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: "Segoe UI";
        src: url(//semalt.com/css/fonts/SegoeUI-SemiBold.ttf);
        font-weight: bold;
        font-style: normal;
    }
    @font-face {
        font-family: "Segoe UI";
        src: url(//semalt.com/css/fonts/SegoeUI-Bold.ttf);
        font-weight: 700;
        font-style: normal;
    }
    .btree a.linkres{
        color: #0288D1;
        text-decoration: underline;
    }
</style>
<template>
    <div>
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <DedicatedTitle></DedicatedTitle>

        <div class="decontent" v-if="ll">
            <div class="dblock">
                <h2 data-ll="7232">{{ll.i7232}}</h2>
            </div>
            <div class="dblock">
                <div class="dleft">
                    <h3 class="firsth3" data-ll="7233">{{ll.i7233}}</h3>
                    <ul>
                        <li v-for="(item, index) in ll.i7234.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>
                <div class="img-r"><img src="/img/dedicated/img-01.png"></div>
            </div>
            <div class="dblock-bg"></div>
            <div class="dblock dblock-rev">
                <div class="img-l"><img src="/img/dedicated/img-02.png"></div>
                <div class="dright">
                    <h3 data-ll="7235">{{ll.i7235}}</h3>
                    <ul>
                        <li v-for="(item, index) in ll.i7236.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>
            </div>
            <div class="dblock">
                <div class="dleft">
                    <h3 data-ll="7237">{{ll.i7237}}</h3>
                    <ul>
                        <li v-for="(item, index) in ll.i7238.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>
                <div class="img-r"><img src="/img/dedicated/img-03.png"></div>

            </div>
            <div class="dblock-bg"></div>
            <div class="dblock dblock-rev">
                <div class="img-l"><img src="/img/dedicated/img-04.png"></div>

                <div class="dright">
                    <h3 data-ll="7239">{{ll.i7239}}</h3>
                    <ul>
                        <li v-for="(item, index) in ll.i7240.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>
            </div>
            <div class="dblock">
                <div class="dleft">
                    <h3 data-ll="7241">{{ll.i7241}}</h3>
                    <ul>
                        <li v-for="(item, index) in ll.i7242.split('%li')" :key="index"><template v-if="item.trim()"><i class="rd"><i></i></i>{{ item.trim() }}</template></li>
                    </ul>
                </div>
                <div class="img-r">
                    <img src="/img/dedicated/img-05.png" />
                </div>
            </div>
            <div class="dblock-bg2"></div>
            <div class="dblock dblock-label">
                <h2 class="h2btn" data-ll="7243">{{ll.i7243}}</h2>
                <div class="btree">
                    <div class="romb"></div>
                    <h4 data-ll="7244">{{ll.i7244}}</h4>
                    <p data-ll="7245">{{ll.i7245}}</p>
                </div>
                <div class="btree">
                    <div class="romb"></div>
                    <h4 data-ll="7246">{{ll.i7246}}</h4>
                    <p data-ll="7247">{{ll.i7247}}</p>
                </div>
                <div class="btree last-child">
                    <div class="romb"></div>
                    <h4 data-ll="7248">{{ll.i7248}}</h4>
                    <p v-html="ll.i7249.replace('%s','<a class=\'linkres\' href=\'https://semalt.com/reseller-program\' target=\'_blank\'>').replace('%s','</a>')"></p>
                </div>
                <div class="clear"></div>
            </div>

            <div ref="reseller" class="dblock blaunched">
                <h2 class="h2btn" data-ll="7250">{{ll.i7250}}</h2>
                <div v-for="(item, index) in reseller" :key="index" class="breseller">
                    <img class="clflag" :alt="item.country.toUpperCase()" :src="'/img/flags3/iso/flat/32/'+ item.country +'.png'" />
                    <img class="rlogo" :src="item.logo" />
                    <a :href="item.site" target="_blank" rel="nofollow">{{item.site}}</a>
                </div>
                <div class="clear"></div>
            </div>

        </div>

        <MoreQuestions></MoreQuestions>
    </div>
</template>
<script>
    /* eslint-disable */
    import DedicatedTitle from '../components/block/DedicatedTitle';
    import MoreQuestions from '../components/block/MoreQuestions';
    import axios from 'axios'
    export default {
        name: 'DedicatedSemaltDashboard',
        components: {
            DedicatedTitle,
            MoreQuestions
        },
        data: function() {
            return {
                ll: null,
                reseller:[],
                manager:null
            }
        },
        watch:{
            lang(to, from){
                this.getll();
            }
        },
        methods: {
            async init(){
                this.route();
                await this.getll();
                await this.main();
                this.aname();
            },
            async getll(){
                let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["dedicated-semalt-dashboard"]} });
                if(res && res.data && res.data.datall) {
                    this.ll = res.data.datall;
                }
            },
            async main(){
                let res = await axios.post('/api/main/get', {controller:[{name:"dedicated"}, {name:"manager"}] });
                if(res && res.data ) {
                    if(res.data.dedicated){
                        this.reseller = res.data.dedicated;
                    }
                    if(res.data.manager){
                        this.manager = res.data.manager;
                    }
                }
            },
            aname(){
                this.$nextTick(()=>{
                    setTimeout(()=>{
                        if(location.hash && location.hash=='#reseller' ||
                            this.$route.query && this.$route.query.a && this.$route.query.a=='reseller'){
                            let slide = this.$refs[`reseller`]
                            let top = window.scrollY + slide.getBoundingClientRect().y;
                            window.scrollTo(0, top);
                        }
                    },200);
                });
            },
            route(){
                if(this.$route.params.lang){
                    if(this.$root.languages.includes(this.$route.params.lang)){
                        if(this.$route.params.lang != this.$root.lang) {
                            this.$root.lang = this.$route.params.lang;
                        }
                    }
                    else{
                        this.$router.push('/en/dedicated-semalt-dashboard');
                        this.$router.push('/dedicated-semalt-dashboard');
                        this.$root.lang = 'en';
                    }
                }
            },
        },
        computed:{
            lang(){
                return this.$root.lang;
            }
        },
        mounted(){
            this.init();
        }
    }
</script>

<style src="../assets/less/dedicated.less" lang="less" scoped></style>